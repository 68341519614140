import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

import { PaymentMethod, UserCurrency } from "../../generated/globalTypes";
import getPaymentMethodDisplay from "../../utils/getPaymentMethodDisplay";

interface IPaymentDetailsCardProps {
  bankName?: string;
  bankAccountNumber?: string | null;
  bankAddress1?: string | null;
  bankAddress2?: string | null;
  bankCity?: string | null;
  bankState?: string | null;
  bankCountry?: string | null;
  bankPostalCode?: string | null;
  beneficiaryAddress1?: string | null;
  beneficiaryAddress2?: string | null;
  beneficiaryCity?: string | null;
  beneficiaryName?: string | null;
  beneficiaryState?: string | null;
  beneficiaryCountry?: string | null;
  beneficiaryPostalCode?: string | null;
  branchCode?: string | null;
  companyRegistrationNumber?: string | null;
  currency?: UserCurrency | null;
  ecoPayzEmail?: string | null;
  ibanNumber?: string | null;
  netellerEmail?: string | null;
  netellerId?: string | null;
  netellerName?: string | null;
  payeeName: string;
  paymentMethod: PaymentMethod;
  paypalEmail?: string | null;
  paypalName?: string | null;
  playerAccountHolderName?: string | null;
  playerAccountNumber?: string | null;
  recipientName?: string | null;
  recipientAddress1?: string | null;
  recipientAddress2?: string | null;
  recipientCity?: string | null;
  recipientState?: string | null;
  recipientCountry?: string | null;
  recipientPostalCode?: string | null;
  reference?: string | null;
  routingNumber?: string | null;
  skrillEmail?: string | null;
  skrillAccountHolderName?: string | null;
  skrillAccountNumber?: string | null;
  skrillAddress1?: string | null;
  skrillAddress2?: string | null;
  skrillCity?: string | null;
  skrillState?: string | null;
  skrillCountry?: string | null;
  skrillPostalCode?: string | null;
  sortCode?: string | null;
  swiftBicCode?: string | null;
  taxCountry?: string | null;
  vat?: string | null;
  walletAddress?: string | null;
}
const PaymentDetailsCard = ({
  bankName,
  bankAccountNumber,
  bankAddress1,
  bankAddress2,
  bankCity,
  bankState,
  bankCountry,
  bankPostalCode,
  beneficiaryAddress1,
  beneficiaryAddress2,
  beneficiaryCity,
  beneficiaryName,
  beneficiaryState,
  beneficiaryCountry,
  beneficiaryPostalCode,
  branchCode,
  companyRegistrationNumber,
  currency,
  ecoPayzEmail,
  ibanNumber,
  netellerEmail,
  netellerId,
  netellerName,
  payeeName,
  paymentMethod,
  paypalEmail,
  paypalName,
  playerAccountHolderName,
  playerAccountNumber,
  recipientName,
  recipientAddress1,
  recipientAddress2,
  recipientCity,
  recipientState,
  recipientCountry,
  recipientPostalCode,
  reference,
  routingNumber,
  skrillEmail,
  skrillAccountHolderName,
  skrillAccountNumber,
  skrillAddress1,
  skrillAddress2,
  skrillCity,
  skrillState,
  skrillCountry,
  skrillPostalCode,
  sortCode,
  swiftBicCode,
  taxCountry,
  vat,
  walletAddress,
}: IPaymentDetailsCardProps) => {
  const classes = useStyles();
  const renderAttributeSubtitle = (label: string) => {
    return (
      <div>
        <Typography component="h6" variant="body1">
          {label}
        </Typography>
      </div>
    );
  };

  const renderAttributeItem = (label: string, value: any) => {
    return (
      <div className={classes.attributeItem}>
        <Typography component="dt" variant="body1">
          {label}:
        </Typography>
        <Typography component="dd" variant="body1">
          {value}
        </Typography>
      </div>
    );
  };

  const renderPaymentMethodDetails = () => {
    if (paymentMethod === PaymentMethod.BANKWIRE) {
      return (
        <>
          <dl className={classes.attributes}>
            {renderAttributeSubtitle("Beneficiary")}
            {renderAttributeItem("Name", beneficiaryName)}
            {renderAttributeItem("Address", beneficiaryAddress1)}
            {beneficiaryAddress2 &&
              renderAttributeItem("Address 2", beneficiaryAddress2)}
            {renderAttributeItem("City", beneficiaryCity)}
            {renderAttributeItem("State/Province", beneficiaryState)}
            {renderAttributeItem("Country", beneficiaryCountry)}
            {renderAttributeItem("Postal Code", beneficiaryPostalCode)}
          </dl>
          <dl className={classes.attributes}>
            {renderAttributeSubtitle("Bank Details")}
            <div className={classes.attributesGrouped}>
              <div>
                {renderAttributeItem("Name", bankName)}
                {renderAttributeItem("Address", bankAddress1)}
                {bankAddress2 && renderAttributeItem("Address 2", bankAddress2)}
                {renderAttributeItem("City", bankCity)}
                {renderAttributeItem("State/Province", bankState)}
                {renderAttributeItem("Country", bankCountry)}
                {renderAttributeItem("Postal Code", bankPostalCode)}
              </div>
              <div>
                {renderAttributeItem("Account Number", bankAccountNumber)}
                {renderAttributeItem("Routing Number", routingNumber)}
                {renderAttributeItem("Branch Code", branchCode)}
                {renderAttributeItem("Sort Code", sortCode)}
                {renderAttributeItem("Swift/BIC", swiftBicCode)}
                {renderAttributeItem("IBAN", ibanNumber)}
                {renderAttributeItem("Reference", reference)}
              </div>
            </div>
          </dl>
        </>
      );
    } else if (paymentMethod === PaymentMethod.BTCTRANSFER) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("BTC Details")}
          {renderAttributeItem("Wallet Address", walletAddress)}
        </dl>
      );
    } else if (paymentMethod === PaymentMethod.CHECK) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("Check Recipient")}
          {renderAttributeItem("Name", recipientName)}
          {renderAttributeItem("Address", recipientAddress1)}
          {recipientAddress2 &&
            renderAttributeItem("Address 2", recipientAddress2)}
          {renderAttributeItem("City", recipientCity)}
          {renderAttributeItem("State/Province", recipientState)}
          {renderAttributeItem("Country", recipientCountry)}
          {renderAttributeItem("Postal Code", recipientPostalCode)}
        </dl>
      );
    } else if (paymentMethod === PaymentMethod.ECOPAYZ) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("EcoPayz Details")}
          {renderAttributeItem("Email Address", ecoPayzEmail)}
        </dl>
      );
    } else if (paymentMethod === PaymentMethod.NETELLER) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("Neteller Details")}
          {renderAttributeItem("Email Address", netellerEmail)}
          {renderAttributeItem("Account ID", netellerId)}
          {renderAttributeItem("Name", netellerName)}
        </dl>
      );
    } else if (paymentMethod === PaymentMethod.PAYPAL) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("PayPal Details")}
          {renderAttributeItem("Email Address", paypalEmail)}
          {renderAttributeItem("Name", paypalName)}
        </dl>
      );
    } else if (paymentMethod === PaymentMethod.PLAYERACCOUNT) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("Player Account Details")}
          {renderAttributeItem("Name", playerAccountHolderName)}
          {renderAttributeItem("Account Number", playerAccountNumber)}
        </dl>
      );
    } else if (paymentMethod === PaymentMethod.SKRILL) {
      return (
        <dl className={classes.attributes}>
          {renderAttributeSubtitle("Skrill Details")}
          <div className={classes.attributesGrouped}>
            <div>
              {renderAttributeItem("Name", skrillAccountHolderName)}
              {renderAttributeItem("Email Address", skrillEmail)}
              {renderAttributeItem("Account Number", skrillAccountNumber)}
            </div>
            <div>
              {renderAttributeItem("Address", skrillAddress1)}
              {skrillAddress2 &&
                renderAttributeItem("Address 2", skrillAddress2)}
              {renderAttributeItem("City", skrillCity)}
              {renderAttributeItem("State/Province", skrillState)}
              {renderAttributeItem("Country", skrillCountry)}
              {renderAttributeItem("Postal Code", skrillPostalCode)}
            </div>
          </div>
        </dl>
      );
    }
  };

  return (
    <dl className={classes.attributes}>
      {renderAttributeItem("Payee Name", payeeName)}
      {renderAttributeItem(
        "Company Registration Number",
        companyRegistrationNumber || "N/A"
      )}
      {renderAttributeItem(
        "Payment Method",
        getPaymentMethodDisplay(paymentMethod)
      )}
      {renderAttributeItem("Currency", currency)}
      {taxCountry && renderAttributeItem("Tax Country", taxCountry)}
      {vat && renderAttributeItem("VAT Number", vat)}
      {renderPaymentMethodDetails()}
    </dl>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  attributes: {
    maxWidth: 1200,
    "&+&": {
      borderTop: `1px solid ${palette.divider}`,
      paddingTop: spacing(2),
    },

    "& h6": {
      marginBottom: spacing(1),
      textTransform: "uppercase",
      fontSize: "0.9em",
      fontWeight: "bold",
      color: palette.text.secondary,
    },
  },
  attributeItem: {
    display: "flex",
    gap: spacing(1),
    padding: spacing(0.5, 0),

    "& > dt": {
      fontWeight: "bold",
      minWidth: "12%",
      width: "100%",
      maxWidth: 200,
      padding: 0,
    },
    "& > dd": {
      margin: 0,
      padding: 0,
      width: "90%",
    },
  },
  attributesGrouped: {
    display: "flex",
    gap: spacing(2),

    "& > div": {
      flex: 1,
    },
  },
}));

export default PaymentDetailsCard;
