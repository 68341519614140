import React, { useState, useRef } from "react";
import { integration_integration_integration } from "../../generated/Integration";
import {
  Paper,
  Breadcrumbs,
  Typography,
  Button,
  makeStyles,
  Link,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
} from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { useAuth0 } from "../../utils/auth0Provider";
import { format } from "date-fns";
import { Skeleton } from "@material-ui/lab";
import IntegrationsStatusChip from "../IntegrationsStatusChip/IntegrationsStatusChip";
import IntegrationPostbackList from "../IntegrationPostbackList/IntegrationPostbackList";
import IntegrationPostbackForm from "../IntegrationPostbackForm/IntegrationPostbackForm";

interface IIntegrationShowProps {
  loading: boolean;
  integration?: integration_integration_integration | null;
}

const IntegrationsShow = ({ loading, integration }: IIntegrationShowProps) => {
  const history = useHistory();
  const classes = useStyles();
  const { hasPermission } = useAuth0();
  const manageIntegrations = hasPermission("manage:integrations");
  const [isPostbackEditMode, setIsPostbackEditMode] = useState(false);
  const togglePostbackEditMode = () => {
    setIsPostbackEditMode(!isPostbackEditMode);
    // do something else
  };

  const renderDefinitionItem = (label: string, value: any) => {
    return (
      <div className={classes.cardListItem}>
        {loading ? (
          <Skeleton component="dt" variant="text" />
        ) : (
          <Typography component="dt" variant="body1">
            {label}
          </Typography>
        )}
        {loading ? (
          <Skeleton component="dd" variant="text" width={210} />
        ) : (
          <Typography component="dd" variant="body1">
            {value}
          </Typography>
        )}
      </div>
    );
  };

  const cardDetails = useRef<HTMLDivElement>(null);
  const cardPostbacks = useRef<HTMLDivElement>(null);
  const cardTracking = useRef<HTMLDivElement>(null);
  
  let scrollTID: NodeJS.Timeout;
  const scrollAndHighlightCard = (cardRef: React.RefObject<HTMLDivElement>) => {
    clearTimeout(scrollTID);
    [cardDetails, cardPostbacks, cardTracking].forEach((card) =>
      cardRef.current?.classList.remove("highlight")
    );
    if (cardRef.current) {
      window.scrollTo(0, cardRef.current.offsetTop);
      cardRef.current.scrollIntoView({ behavior: "smooth" });
      cardRef.current.classList.add("highlight");
      scrollTID = setTimeout(
        () => cardRef.current?.classList.remove("highlight"),
        2000
      );
    }
  };

  return (
    <>
      <Paper>
        <div className={classes.header}>
          <div className={classes.headerItem}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <Link
                underline="hover"
                color="inherit"
                onClick={() => history.push("/tech/integrations")}
              >
                <ChevronLeft />
                Integrations
              </Link>
              <Typography color="textPrimary">
                {loading ? (
                  <Skeleton width={60} />
                ) : (
                  `${integration?.integrationType} (${integration?.accountId})`
                )}
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.headerItem}>
            {manageIntegrations && (
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  history.push(`/tech/integrations/${integration?.id}/edit`)
                }
              >
                Edit
              </Button>
            )}
          </div>
        </div>
        <Divider />
        <div className={classes.gRoot}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={2}>
              <List>
                <ListItem button onClick={() => scrollAndHighlightCard(cardDetails)}>
                  <ListItemText primary="Details" />
                </ListItem>
                <ListItem button onClick={() => scrollAndHighlightCard(cardPostbacks)}>
                  <ListItemText primary="Postback Setup" />
                </ListItem>
                <ListItem button onClick={() => scrollAndHighlightCard(cardTracking)}>
                  <ListItemText primary="Tracking Setup" />
                </ListItem>
                {/* 
                
                @TODO: Add back when we have the UI for these, as well as their card refs
                
                <ListItem button>
                  <ListItemText primary="Authentication" />
                </ListItem>
                <ListItem button>
                  <ListItemText primary="Disconnect" />
                </ListItem> 

                */}
              </List>
            </Grid>
            <Grid item xs={12} sm={10}>
              <div className={classes.cardContainer}>
                <Card ref={cardDetails} variant="outlined" className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Details
                    </Typography>
                    <dl className={classes.cardList}>
                      {renderDefinitionItem(
                        "Type",
                        integration?.integrationType
                      )}
                      {renderDefinitionItem(
                        "Last Updated",
                        integration?.updatedAt
                          ? format(
                              new Date(integration?.updatedAt),
                              "MM/dd/yyyy HH:mmaa"
                            )
                          : "&mdash;"
                      )}
                      {renderDefinitionItem(
                        "Account ID",
                        integration?.accountId
                      )}
                      {renderDefinitionItem(
                        "External ID",
                        integration?.externalId
                      )}
                      {renderDefinitionItem(
                        "Active",
                        <IntegrationsStatusChip
                          integrationId={integration?.id}
                          active={integration?.active}
                        />
                      )}
                    </dl>
                  </CardContent>
                </Card>

                <Card ref={cardPostbacks} variant="outlined" className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      POSTBACKS
                      {!isPostbackEditMode && (
                        <Button
                          color="default"
                          variant="outlined"
                          className={classes.cardEditButton}
                          onClick={togglePostbackEditMode}
                          size="small"

                        >
                          Edit
                        </Button>
                      )}
                    </Typography>

                    {isPostbackEditMode ? (
                      <IntegrationPostbackForm
                        type="edit"
                        integrationId={integration?.id}
                        events={integration?.events || []}
                        loading={loading}
                        onCancel={() => setIsPostbackEditMode(false)}
                      />
                    ) : (
                      <IntegrationPostbackList
                        loading={loading}
                        integration={integration}
                      />
                    )}
                  </CardContent>
                </Card>


                <Card ref={cardTracking} variant="outlined" className={classes.card}>
                  <CardContent>
                    <Typography
                      className={classes.cardTitle}
                      color="textSecondary"
                    >
                      Tracking Setup
                    </Typography>
                    <dl className={classes.cardList}>
                      {renderDefinitionItem("Type", "Manually Appended")}
                    </dl>
                  </CardContent>
                </Card>
              </div>
            </Grid>
          </Grid>
        </div>
      </Paper>
      {/* @TODO Add IntegrationForm component here */}
    </>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  "@keyframes highlight": {
    "0%": {
      backgroundColor: palette.grey[50],
    },
    "100%": {
      backgroundColor: "inherit",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(2),
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
  breadcrumbs: {
    paddingBottom: spacing(1),
    "& a, & li": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& a": {
      cursor: "pointer",
    },
  },
  gRoot: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: spacing(2),
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: palette.grey[200],
  },
  card: {
    marginBottom: spacing(2),
    maxWidth: 800,
    margin: "0 auto",

    "&.highlight": {
      animation: `$highlight .5s ease-in-out`,
    },
  },
  cardGroupTitle: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    textTransform: "uppercase",
  },
  cardTitle: {
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: 600,
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
  },
  cardList: {
    width: "100%",
  },
  cardListItem: {
    display: "flex",
    gap: spacing(1),
    marginBottom: spacing(1),
    "& > dt": {
      fontWeight: "bold",
      minWidth: "12%",
    },
    "& > dd": {
      margin: 0,
      padding: 0,
      width: "90%",
    },
  },
  cardListItemTable: {
    width: "inherit",
  },
  cardEditButton: {
    float: "right",
    fontSize: 12,
    minWidth: 50,
    padding: spacing(0.125, 0.25),
  },
}));

export default IntegrationsShow;
