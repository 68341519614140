import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { useAuth0 } from "../../utils/auth0Provider";
import { InstanceSettings } from "../../generated/InstanceSettings";
import InstanceSettingsQuery from "../../queries/InstanceSettingsQuery";
import Loading from "../Loading";

import { useIntercom } from "react-use-intercom";
import LayoutRoutes from "../LayoutRoutes/LayoutRoutes";

const Layout = () => {
  const { boot } = useIntercom();

  const { hasPermission, isAuthenticated, loading, user } = useAuth0();

  const [animationComplete, setAnimationComplete] = useState(false);
  const [animationTimeout, setAnimationTimeout] = useState<number | undefined>(
    undefined
  );
  const [userApproved, setUserApproved] = useState(false);

  const { data, loading: instanceSettingsLoading } = useQuery<InstanceSettings>(
    InstanceSettingsQuery
  );

  const [favi, setFavi] = useState("../../../public/favicon.ico");

  if (userApproved && hasPermission("boot:intercom")) {
    boot({
      customAttributes: {
        instance: process.env.REACT_APP_INSTANCE,
      },
      email: user?.email,
      name: `${user?.name} - ${process.env.REACT_APP_INSTANCE}`,
    });
  }

  useEffect(() => {
    if (
      isAuthenticated &&
      !!user &&
      user["https://intelitics.com/app_metadata"].approved
    ) {
      setUserApproved(true);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (!animationComplete && animationTimeout === undefined) {
      const timeoutId = window.setTimeout(() => {
        setAnimationComplete(true);
        setAnimationTimeout(undefined);
      }, 1000);
      setAnimationTimeout(timeoutId);
    }
  }, [animationComplete, animationTimeout]);

  useEffect(() => {
    if (data?.instanceSettings.favicon) {
      setFavi(data?.instanceSettings.favicon);
    }
  }, [loading, data]);

  if (loading || instanceSettingsLoading || !animationComplete) {
    return <Loading />;
  }

  const faviconError = () => {
    setFavi("../../../public/favicon.ico");
  };

  return (
    <>
      <CssBaseline />

      <Helmet>
        <title>{data?.instanceSettings.title}</title>
        <link
          rel="icon"
          type="image/png"
          href={favi}
          onError={() => faviconError()}
        />
      </Helmet>
      <BrowserRouter>
        <LayoutRoutes
          data={data as InstanceSettings}
          userApproved={userApproved}
        />
      </BrowserRouter>
    </>
  );
};

export default Layout;
