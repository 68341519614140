import { gql } from "@apollo/client";

const IntegrationQuery = gql`
  query integration($integrationId: ID!) {
    integration(integrationId: $integrationId) {
      integration {
        id
        accountId
        active
        errorMessage
        externalId
        integrationType
        createdAt
        updatedAt
        createdBy {
          id
          displayId
          name
        }
        events {
          id
          includeValue
          externalEvent
          internalEvent
          createdAt
          updatedAt
          createdBy {
            id
            displayId
            name
        }
        updatedBy {
          id
          displayId
          name
        }
      }
    }
   }
  }
`;

export default IntegrationQuery;
