import { gql } from "@apollo/client";

const CreateWebhook = gql`
  mutation CreateWebhook(
    $actionType: WebhookActionType!
    $httpBody: String
    $httpMethod: WebhookHttpMethod!
    $offerId: Int
    $url: String!
    $userId: ID
  ) {
    createWebhook(
      input: {
        actionType: $actionType
        httpBody: $httpBody
        httpMethod: $httpMethod
        offerId: $offerId
        url: $url
        userId: $userId
      }
    ) {
      webhook {
        id
        actionType
        url
        offer {
          id
        }
        user {
          id
        }
      }
    }
  }
`;

export default CreateWebhook;
