import { useApolloClient } from "@apollo/client";
import {
  Breadcrumbs,
  Button,
  Chip,
  Link,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { UserCurrency } from "../../generated/globalTypes";
import { User_user_user_paymentDetails_paymentDetailsImplementation as IPaymentDetails } from "../../generated/User";
import PaymentDetailsCard from "../PaymentDetailsCard";
import PartnerViewIdQuery from "../../queries/PartnerViewIdQuery";
import { ChevronLeft, FiberManualRecordTwoTone } from "@material-ui/icons";
import UserWebhookList from "../UserWebhookList/UserWebhookList";
import { useAuth0 } from "../../utils/auth0Provider";

export interface IUserProperties {
  id: string;
  customAttributes: {};
  currency: UserCurrency | null;
  displayId: number | null;
  email: string;
  name: string;
  paymentDetails: IPaymentDetails | null;
  approved: boolean;
}

const UserShow = ({
  id,
  customAttributes,
  currency,
  displayId,
  email,
  name,
  paymentDetails,
  approved,
}: IUserProperties) => {
  const classes = useStyles();
  const client = useApolloClient();
  const history = useHistory();
  const { hasPermission, user } = useAuth0();

  const attributeEntries: Array<[string, string]> = Object.entries(
    customAttributes
  );

  const handlePartnerOfferView = () => {
    client.writeQuery({
      data: {
        partnerViewCurrency: currency,
        partnerViewId: id,
      },
      query: PartnerViewIdQuery,
    });

    history.push("/offers");
  };

  const renderAttributeItem = (label: string, value: any) => {
    return (
      <div className={classes.attributeItem}>
        <Typography component="dt" variant="body1">
          {label}:
        </Typography>
        <Typography component="dd" variant="body1">
          {value}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <div className={classes.headerItem}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <Link
                underline="hover"
                color="inherit"
                onClick={(e) => history.push("/users")}
              >
                <ChevronLeft />
                Users
              </Link>
              <Typography color="textSecondary">User Information</Typography>
              <Typography color="textPrimary">
                {name} ({displayId})
              </Typography>
            </Breadcrumbs>
          </div>
          <div className={classes.headerItem}>
            <Chip
              color={approved ? "primary" : "default"}
              variant="outlined"
              size="small"
              label={approved ? "Active" : "Unapproved"}
              icon={<FiberManualRecordTwoTone />}
            />
            <Button
              color="primary"
              variant="contained"
              onClick={handlePartnerOfferView}
            >
              View Offers as User
            </Button>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6" color="textSecondary">
              Personal
            </Typography>
          </div>
          <dl className={classes.attributes}>
            {renderAttributeItem("Email", email)}
            {renderAttributeItem("Name", name)}
          </dl>
          {attributeEntries.length > 0 && (
            <dl className={classes.attributes}>
              {attributeEntries.map(([key, value], index) => {
                let attributeKey = key.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
                attributeKey =
                  attributeKey[0].toUpperCase() + attributeKey.slice(1);
                return renderAttributeItem(attributeKey, value.toLowerCase());
              })}
            </dl>
          )}
        </div>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <Typography variant="h6" color="textSecondary">
              Payment Details
            </Typography>
          </div>
          {paymentDetails ? (
            <PaymentDetailsCard {...paymentDetails} />
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.section}>
          <UserWebhookList
            userId={id}
            userName={name}
            userDisplayId={displayId}
            canCreateWebhooks={
              hasPermission("manage:webhooks:all") || user?.sub === id
            }
          />
        </div>
      </Paper>
    </>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(1.5, 2, 1),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: palette.divider,
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
  breadcrumbs: {
    "& a, & li": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& a": {
      cursor: "pointer",
    },
  },
  paper: {
    padding: 0,
  },
  section: {
    padding: spacing(2),
  },
  sectionHeader: {
    alignItems: "center",
    display: "flex",
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
    maxWidth: 800,

    "& h6": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
  attributes: {
    margin: spacing(1, 0, 2),
    padding: 0,
    maxWidth: 1200,

    "&+&": {
      borderTop: `1px solid ${palette.divider}`,
      paddingTop: spacing(2),
    },
  },
  attributeItem: {
    display: "flex",
    gap: spacing(1),
    padding: spacing(0.5, 0),

    "& > dt": {
      fontWeight: "bold",
      minWidth: "12%",
      width: "100%",
      maxWidth: 200,
      padding: 0,
    },
    "& > dd": {
      margin: 0,
      padding: 0,
      width: "90%",
    },
  },
}));

export default UserShow;
