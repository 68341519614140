import React, { useState } from "react";

import {
  Breadcrumbs,
  Button,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

import { UserCurrency } from "../../generated/globalTypes";
import { User_user_user_paymentDetails_paymentDetailsImplementation } from "../../generated/User";

import PaymentDetailsCard from "../PaymentDetailsCard";
import PaymentDetailsForm from "../PaymentDetailsForm";
import PersonalDetailsForm from "../PersonalDetailsForm";
import UserWebhookList from "../UserWebhookList/UserWebhookList";

interface ISettingsProps {
  id: string;
  displayId: number | null;
  company: string | null;
  currency: UserCurrency | null;
  email: string;
  name: string;
  paymentDetails: User_user_user_paymentDetails_paymentDetailsImplementation | null;
  phoneNumber: string | null;
}

const Settings = ({
  id,
  displayId,
  company,
  currency,
  email,
  name,
  paymentDetails,
  phoneNumber,
}: ISettingsProps) => {
  const classes = useStyles();
  const [personalDetailsFormOpen, setPersonalDetailsFormOpen] = useState(false);
  const [paymentDetailsFormOpen, setPaymentDetailsFormOpen] = useState(false);
  const renderAttributeItem = (label: string, value: any) => {
    return (
      <div className={classes.attributeItem}>
        <Typography component="dt" variant="body1">
          {label}:
        </Typography>
        <Typography component="dd" variant="body1">
          {value}
        </Typography>
      </div>
    );
  };
  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <div className={classes.headerItem}>
            <Breadcrumbs className={classes.breadcrumbs}>
              <Typography color="textSecondary">Account</Typography>
              <Typography color="textPrimary">Settings</Typography>
            </Breadcrumbs>
          </div>
        </div>

        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <div>
              <Typography variant="h6" color="textSecondary">
                Personal
              </Typography>
            </div>
            <div>
              <Button
                className={classes.editButton}
                color="default"
                variant="outlined"
                onClick={() => setPersonalDetailsFormOpen(true)}
                size="small"
              >
                Edit
              </Button>
            </div>
          </div>
          <dl className={classes.attributes}>
            {renderAttributeItem("Email", email)}
            {renderAttributeItem("Name", name)}
            {renderAttributeItem("Company", company)}
            {renderAttributeItem("Phone Number", phoneNumber)}
            {renderAttributeItem("Display Currency", currency)}
          </dl>
        </div>
        <div className={classes.section}>
          <div className={classes.sectionHeader}>
            <div>
              <Typography variant="h6" color="textSecondary">
                Payment Details
              </Typography>
            </div>
            <div>
              <Button
                className={classes.editButton}
                color="default"
                variant="outlined"
                onClick={() => setPaymentDetailsFormOpen(true)}
                size="small"
              >
                Edit
              </Button>
            </div>
          </div>
          {paymentDetails ? (
            <PaymentDetailsCard {...paymentDetails} />
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.section}>
          <UserWebhookList
            userId={id}
            userName={name}
            userDisplayId={displayId}
            canCreateWebhooks={true}
          />
        </div>
      </Paper>

      <PersonalDetailsForm
        company={company}
        currency={currency}
        email={email}
        name={name}
        onClose={() => setPersonalDetailsFormOpen(false)}
        open={personalDetailsFormOpen}
        phoneNumber={phoneNumber}
      />

      <PaymentDetailsForm
        {...paymentDetails}
        onClose={() => setPaymentDetailsFormOpen(false)}
        open={paymentDetailsFormOpen}
      />
    </>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(1.5, 2, 1),
    borderBottomWidth: 1,
    borderBottomStyle: "solid",
    borderBottomColor: palette.divider,
    minHeight: spacing(7.25),
  },
  headerItem: {
    display: "flex",
    alignItems: "center",
    gap: spacing(2),
  },
  breadcrumbs: {
    "& a, & li": {
      display: "inline-flex",
      alignItems: "center",
    },
    "& a": {
      cursor: "pointer",
    },
  },
  paper: {
    padding: 0,
  },
  section: {
    padding: spacing(2),
  },
  editButton: {
    fontSize: 12,
    minWidth: 50,
    padding: spacing(0.125, 0.25),
  },

  personal: { paddingBottom: spacing(2) },
  sectionHeader: {
    display: "flex",
    gap: spacing(3),
    justifyContent: "space-between",
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,
    maxWidth: 800,

    "& h6": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
    },

    "& > div": {
      display: "flex",
      alignItems: "center",
    },
  },
  attributes: {
    margin: spacing(1, 0, 2),
    padding: 0,
    maxWidth: 800,

    "&+&": {
      borderTop: `1px solid ${palette.divider}`,
      paddingTop: spacing(2),
    },
  },
  attributeItem: {
    display: "flex",
    gap: spacing(1),
    padding: spacing(0.5, 0),

    "& > dt": {
      fontWeight: "bold",
      minWidth: "12%",
      width: "100%",
      maxWidth: 200,
      padding: 0,
    },
    "& > dd": {
      margin: 0,
      padding: 0,
      width: "90%",
    },
  },
}));

export default Settings;
