import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@material-ui/core";
import IntegrationPostbackListItem from "../IntegrationPostbackListItem/IntegrationPostbackListItem";
import {
  UpdateIntegration_updateIntegration_integration,
  UpdateIntegration_updateIntegration_integration_events,
} from "../../generated/UpdateIntegration";

interface IIntegrationPostbackListProps {
  loading: boolean;
  integration?: UpdateIntegration_updateIntegration_integration | null;
}

const IntegrationShowPostbackList = ({
  loading,
  integration
}: IIntegrationPostbackListProps) => {
  console.log(integration);
  return (
    <Table size="small">
      <TableBody>
        {integration?.events?.length ? (
          integration.events.map(
            (
              integrationEvent: UpdateIntegration_updateIntegration_integration_events,
              index: number
            ) => (
              <IntegrationPostbackListItem
                integrationEvent={integrationEvent}
                index={index}
                key={integrationEvent.id}
              />
            )
          )
        ) : (
          <TableRow>
            <TableCell colSpan={7} align="center">
              {loading ? (
                <CircularProgress thickness={6} color="primary" />
              ) : (
                "No postbacks"
              )}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

export default IntegrationShowPostbackList;
