import { gql } from "@apollo/client";

const UpdateIntegrationEventsMutation = gql`
  mutation UpdateIntegrationEvents(
    $integrationId: ID!
    $events: [IntegrationEventInput!]
  ) {
    updateIntegration(
      integrationId: $integrationId
      input: { events: $events }
    ) {
      integration {
        id
        events {
          id
          includeValue
          externalEvent
          internalEvent
          createdAt
          updatedAt
          createdBy {
            id
            displayId
            name
          }
          updatedBy {
            id
            displayId
            name
          }
        }
      }
      error {
        message
      }
    }
  }
`;

export default UpdateIntegrationEventsMutation;