import React, { useState } from "react";
import WebhookForm from "../WebhookForm";
import { Button, Typography, makeStyles } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import {
  Webhooks as IWebhooksQuery,
  Webhooks_webhooks_edges_node as IWebhook,
} from "../../generated/Webhooks";
import WebhooksQuery from "../../queries/WebhooksQuery";
import { useMutation, useQuery } from "@apollo/client";
import ConfirmationAlert from "../ConfirmationAlert";
import { DeleteWebhook as IDeleteWebhook } from "../../generated/DeleteWebhook";
import DeleteWebhookMutation from "../../mutations/DeleteWebhookMutation";
import WebhookTable from "../WebhookTable/WebhookTable";
import {
  WebhookActionType,
  WebhookHttpMethod,
} from "../../generated/globalTypes";

interface IUserWebhookListProps {
  userId?: string;
  userName?: string;
  userDisplayId?: number | null;
  canCreateWebhooks?: boolean;
}

const UserWebhookList = ({
  userId,
  userName,
  userDisplayId,
  canCreateWebhooks,
}: IUserWebhookListProps) => {
  const classes = useStyles();
  const [webhookToDelete, setWebhookToDelete] = useState<IWebhook>();
  const [webhookDeleteConfirmation, setWebhookDeleteConfirmation] = useState(
    false
  );
  const [webhookFormOpen, setCreateWebhookOpen] = useState(false);
  const [webhookFormType, setWebhookFormType] = useState<"Create" | "Update">(
    "Create"
  );
  const [webhookUpdate, setWebhookUpdate] = useState<IWebhook | undefined>();
  const { data, loading } = useQuery<IWebhooksQuery>(WebhooksQuery, {
    variables: {
      userId,
      offerId: null,
    },
  });
  const [deleteWebhookMutation] = useMutation<IDeleteWebhook>(
    DeleteWebhookMutation
  );

  const onCreateWebhook = () => {
    setWebhookFormType("Create");
    setCreateWebhookOpen(true);
    setWebhookUpdate({
      id: "",
      actionType: Object.keys(WebhookActionType)[0] as WebhookActionType,
      httpBody: null,
      httpMethod: Object.keys(WebhookHttpMethod)[0] as WebhookHttpMethod,
      url: "",
      user: {
        id: userId,
        name: userName,
        displayId: userDisplayId,
      },
    } as IWebhook);
  };

  const onUpdateWebhook = (webhook: IWebhook) => {
    setWebhookFormType("Update");
    setCreateWebhookOpen(true);
    setWebhookUpdate(webhook);
  };

  const onDeleteWebhook = (webhook: IWebhook) => {
    setWebhookToDelete(webhook);
    setWebhookDeleteConfirmation(true);
  };

  const deleteWebhook = () => {
    setWebhookDeleteConfirmation(false);
    if (!webhookToDelete) return;
    deleteWebhookMutation({
      awaitRefetchQueries: true,
      refetchQueries: ["Webhooks"],
      variables: { webhookId: webhookToDelete!.id },
    }).then(() => setWebhookToDelete(undefined));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div>
          <Typography variant="h6" color="textSecondary">
            Webhook Templates
          </Typography>
        </div>
        {canCreateWebhooks && (
          <div>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={() => onCreateWebhook()}
              startIcon={<AddIcon />}
            >
              Add Template
            </Button>
          </div>
        )}
      </div>
      <WebhookTable
        loading={loading}
        hideUserDetails
        data={data?.webhooks?.edges.map(({ node }) => node) as IWebhook[]}
        onDelete={onDeleteWebhook}
        onUpdate={onUpdateWebhook}
        renderNoOptions={() => (
          <div>
            <Typography color="textSecondary" variant="body2">
              No webhook templates found{userName ? ` for ${userName}` : ""}.
            </Typography>
            {canCreateWebhooks && (
              <Button
                color="primary"
                variant="text"
                size="small"
                onClick={() => onCreateWebhook()}
                startIcon={<AddIcon />}
              >
                Add Template
              </Button>
            )}
          </div>
        )}
      />
      <WebhookForm
        onExited={() => webhookUpdate && setWebhookUpdate(undefined)}
        offerId={null}
        open={webhookFormOpen}
        onClose={() => setCreateWebhookOpen(false)}
        type={webhookFormType}
        updateWebhookActionType={webhookUpdate?.actionType}
        updateWebhookHttpBody={webhookUpdate?.httpBody}
        updateWebhookHttpMethod={webhookUpdate?.httpMethod}
        updateWebhookId={webhookUpdate?.id}
        updateWebhookUrl={webhookUpdate?.url}
        updateWebhookUser={webhookUpdate?.user}
      />
      <ConfirmationAlert
        open={webhookDeleteConfirmation}
        onNegative={() => setWebhookDeleteConfirmation(false)}
        onPositive={deleteWebhook}
        positiveAction="Delete"
        title="Delete Webhook"
        content="Are you sure you want to delete this webhook?"
      />
    </div>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  root: {
    marginBottom: spacing(6),
  },
  header: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,

    "& h6": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
    },

    "& > div": {
      display: "flex",
      gap: spacing(),
    },
  },
}));

export default UserWebhookList;
