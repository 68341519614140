import React from "react";
import {
  Chip,
  CircularProgress,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  Edit,
  DeleteForever,
  ArrowDownward,
  ArrowUpward,
} from "@material-ui/icons";
import { WebhookHttpMethod } from "../../generated/globalTypes";
import { Webhooks_webhooks_edges_node as IWebhook } from "../../generated/Webhooks";
interface IWebhookTableProps {
  loading: boolean;
  data: IWebhook[];
  hideUserDetails?: boolean;
  onUpdate?: (webhook: IWebhook) => void;
  onDelete?: (webhook: IWebhook) => void;
  renderNoOptions?: () => React.ReactNode;
}

const WebhookTable = ({
  loading,
  data,
  hideUserDetails,
  onUpdate,
  onDelete,
  renderNoOptions,
}: IWebhookTableProps) => {
  const classes = useStyles();
  const onUpdateWebhook = (webhook: IWebhook) => onUpdate && onUpdate(webhook);
  const onDeleteWebhook = (webhook: IWebhook) => onDelete && onDelete(webhook);
  return (
    <Table>
      <TableHead>
        <TableCell className={classes.webhookId}>Pixel ID</TableCell>
        <TableCell className={classes.webhookActionType}>Event</TableCell>
        <TableCell className={classes.webhookMethod}>HTTP Method</TableCell>
        <TableCell className={classes.webhookUrl}>URL</TableCell>
        {!hideUserDetails && <TableCell>Partner ID</TableCell>}
        {!hideUserDetails && <TableCell>Partner</TableCell>}
        <TableCell className={classes.webhookButtonsCell}>&nbsp;</TableCell>
      </TableHead>
      <TableBody>
        {data && data.length > 0 ? (
          data.map((webhook) => (
            <TableRow key={webhook.id}>
              <TableCell className={classes.webhookId}>{webhook.id}</TableCell>
              <TableCell className={classes.webhookActionType}>
                {webhook.actionType
                  .split("_")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}
              </TableCell>
              <TableCell className={classes.webhookMethod}>
                <Chip
                  label={webhook.httpMethod}
                  variant="outlined"
                  size="small"
                  className={`http-${webhook.httpMethod.toLocaleLowerCase()}`}
                  icon={
                    ["POST", "PUT", "PATCH"].includes(webhook.httpMethod) ? (
                      <ArrowUpward />
                    ) : webhook.httpMethod === WebhookHttpMethod.GET ? (
                      <ArrowDownward />
                    ) : (
                      undefined
                    )
                  }
                />
              </TableCell>
              <TableCell className={classes.webhookUrl}>
                {webhook.url}
              </TableCell>
              {!hideUserDetails && (
                <TableCell>{webhook.user.displayId}</TableCell>
              )}
              {!hideUserDetails && <TableCell>{webhook.user.name}</TableCell>}
              <TableCell className={classes.webhookButtonsCell}>
                <div className={classes.webhookButtons}>
                  <IconButton onClick={() => onUpdateWebhook(webhook)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => onDeleteWebhook(webhook)}>
                    <DeleteForever color="error" />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={hideUserDetails ? 5 : 7}>
              <Typography align="center" variant="body2">
                {loading ? (
                  <CircularProgress thickness={6} color="primary" />
                ) : renderNoOptions ? (
                  renderNoOptions()
                ) : (
                  "No user webhooks found."
                )}
              </Typography>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  webhookId: {
    whiteSpace: "nowrap",
    width: 310,
  },
  webhookActionType: {
    width: 110,
  },
  webhookMethod: {
    width: 150,

    "& .MuiChip-root": {
      minWidth: 70,
      fontWeight: "bold",

      "& .MuiChip-icon": {
        color: "currentColor",
        width: "0.65em",
        height: "0.65em",
      },

      "&.MuiChip-sizeSmall": {
        "& .MuiChip-icon": {
          marginLeft: 6,
        },
      },
      "&.http-get": {
        color: palette.info.main,
        borderColor: palette.info.main,
      },
      "&.http-post": {
        color: palette.success.main,
        borderColor: palette.success.main,
      },
      "&.http-put": {
        color: palette.warning.main,
        borderColor: palette.warning.main,
      },
      "&.http-patch": {
        color: palette.warning.dark,
        borderColor: palette.warning.dark,
      },
      "&.http-delete": {
        color: palette.error.main,
        borderColor: palette.error.main,
      },
    },
  },
  webhookUrl: {
    width: "max-content",
    maxWidth: 300,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  webhookButtonsCell: {
    width: 80,
  },
  webhookButtons: {
    display: "flex",
    gap: spacing(1),
  },
}));

export default WebhookTable;
