import {
  Chip,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import React from "react";
import { UpdateIntegration_updateIntegration_integration_events } from "../../generated/UpdateIntegration";

interface IIntegrationPostbackListItemProps {
  index: number;
  integrationEvent: UpdateIntegration_updateIntegration_integration_events;
}

const IntegrationPostbackListItem = ({
  index,
  integrationEvent,
}: IIntegrationPostbackListItemProps) => {
  return (
    <TableRow>
      <TableCell style={{ width: "10px" }}>
        <Typography variant="body2" component="strong">
          {index + 1}
        </Typography>
      </TableCell>
      <TableCell style={{ width: "60px" }}>
        <Chip
          label={integrationEvent.internalEvent}
          size="small"
          color="default"
          variant="outlined"
          style={{ width: "75%" }}
        />
      </TableCell>
      <TableCell style={{ width: "50px" }}>{integrationEvent.externalEvent}</TableCell>
      <TableCell style={{ width: "25px" }}>
      <Chip
          label={integrationEvent.includeValue ? "Event and Value" : "Event"}
          size="small"
          color="default"
          variant="outlined"
          style={{ width: "75%" }}
        />
      </TableCell>
    </TableRow>
  );
};

export default IntegrationPostbackListItem;
