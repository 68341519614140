import React, { Fragment } from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import Auth0Redirect from "../../components/Auth0Redirect";
import AccountPoolListContainer from "../../containers/AccountPoolListContainer";
import AccountPoolShowContainer from "../../containers/AccountPoolShowContainer/AccountPoolShowContainer";
import AdjustmentListContainer from "../../containers/AdjustmentListContainer";
import AdvertiserListContainer from "../../containers/AdvertiserListContainer";
import AdvertiserShowContainer from "../../containers/AdvertiserShowContainer";
import BrandListContainer from "../../containers/BrandListContainer";
import BrandShowContainer from "../../containers/BrandShowContainer";
import ClickShowContainer from "../../containers/ClickShowContainer/ClickShowContainer";
import InstanceSettingsListContainer from "../../containers/InstanceSettingsListContainer";
import MonthlyPaymentsListContainer from "../../containers/MonthlyPaymentsListContainer";
import OfferListContainer from "../../containers/OfferListContainer";
import OfferShowContainer from "../../containers/OfferShowContainer";
import OfferPoolListContainer from "../../containers/OfferPoolListContainer";
import SettingsContainer from "../../containers/SettingsContainer";
import SubagreementLogsListContainer from "../../containers/SubagreementLogsListContainer";
import UserListContainer from "../../containers/UserListContainer";
import UserShowContainer from "../../containers/UserShowContainer";
import ConversionManagement from "../ConversionManagement";
import CsvForm from "../CsvForm";
import ErrorPage from "../ErrorPage";
import Header from "../Header";
import Landing from "../Landing";
import PrivacyPolicy from "../PrivacyPolicy";
import RedirectRoute from "../RedirectRoute";
import Report from "../Report";
import SmartPixelGenerator from "../SmartPixelGenerator";
import TermsOfService from "../TermsOfService";
import OfferPoolShowContainer from "../../containers/OfferPoolShowContainer/OfferPoolShowContainer";
import IntegrationsListContainer from "../../containers/IntegrationsListContainer/IntegrationsListContainer";
import IntegrationsShowContainer from "../../containers/IntegrationsShowContainer/IntegrationsShowContainer";
import IntegrationsFormContainer from "../../containers/IntegrationsFormContainer/IntegrationsFormContainer";
import { InstanceSettings } from "../../generated/InstanceSettings";
import { useAuth0 } from "../../utils/auth0Provider";
import usePageTracking from "../../utils/usePageTracking";

interface ILayoutRoutesProps {
  data: InstanceSettings;
  userApproved: boolean;
}

const LayoutRoutes = ({ data, userApproved }: ILayoutRoutesProps) => {
  usePageTracking();
  const classes = useStyles();
  const { hasPermission } = useAuth0();

  return (
    <Switch>
      <RedirectRoute
        component={Landing}
        exact
        path="/"
        redirectTo="/dashboard"
        shouldRedirect={userApproved}
      />

      <Route component={Auth0Redirect} path={["/login", "/signup"]} />
      <Route component={PrivacyPolicy} path="/privacy" />
      <Route component={TermsOfService} path="/terms" />
      <Route component={ErrorPage} path="/error" />

      {/* Header grouped with routes that should display it always */}
      <Fragment>
        <Header logo={data?.instanceSettings.logo} />

        <div className={classes.container}>
          <RedirectRoute
            component={AccountPoolShowContainer}
            exact
            path="/account-pools/:accountPoolId"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:account_pools")}
          />

          <RedirectRoute
            component={AccountPoolListContainer}
            exact
            path="/account-pools"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:account_pools")}
          />

          <RedirectRoute
            component={AdjustmentListContainer}
            exact
            path="/adjustments"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:adjustments")}
          />

          <RedirectRoute
            component={MonthlyPaymentsListContainer}
            exact
            path="/payments"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:monthly_payments")}
          />

          <RedirectRoute
            component={AdvertiserListContainer}
            exact
            path="/advertisers"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:advertisers")}
          />

          <RedirectRoute
            component={AdvertiserShowContainer}
            path="/advertisers/:advertiserId"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:advertisers")}
          />

          <RedirectRoute
            component={BrandListContainer}
            exact
            path="/brands"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={BrandShowContainer}
            path="/brands/:brandId/:tabId"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={SmartPixelGenerator}
            exact
            path="/generator"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:smart_pixels")}
          />

          <RedirectRoute
            component={OfferListContainer}
            exact
            path="/offers"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={OfferShowContainer}
            exact
            path="/offers/:offerId/:tabId"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={SubagreementLogsListContainer}
            exact
            path="/offers/:offerId/subagreements/:userId"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={OfferPoolListContainer}
            exact
            path="/offer-pools"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={OfferPoolShowContainer}
            exact
            path="/offer-pools/:offerPoolId"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={Report}
            exact
            path="/dashboard"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={Report}
            exact
            path="/reports/:reportName"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={SettingsContainer}
            exact
            path="/settings"
            redirectTo="/"
            shouldRedirect={!userApproved}
          />

          <RedirectRoute
            component={UserListContainer}
            exact
            path="/users"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:users")}
          />

          <RedirectRoute
            component={ConversionManagement}
            exact
            path="/tech/conversion-management"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:clicks")}
          />

          <RedirectRoute
            component={ClickShowContainer}
            exact
            path="/tech/conversion-management/:clickId"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:clicks")}
          />

          <RedirectRoute
            component={CsvForm}
            exact
            path="/tech/import-csv"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:clicks")}
          />

          <RedirectRoute
            component={InstanceSettingsListContainer}
            exact
            path="/tech/instance-settings"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:instance_settings")}
          />

          <RedirectRoute
            component={IntegrationsListContainer}
            exact
            path="/tech/integrations"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:integrations")}
          />

          <RedirectRoute
            component={IntegrationsFormContainer}
            exact
            path="/tech/integrations/new"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:integrations")}
          />

          <RedirectRoute
            component={IntegrationsFormContainer}
            exact
            path="/tech/integrations/:integrationId([0-9]*)/edit"
            redirectTo="/"
            shouldRedirect={!hasPermission("manage:integrations")}
          />

          <RedirectRoute
            component={IntegrationsShowContainer}
            exact
            path="/tech/integrations/:integrationId([0-9]*)"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:integrations")}
          />

          <RedirectRoute
            component={UserShowContainer}
            exact
            path="/users/:userId"
            redirectTo="/"
            shouldRedirect={!hasPermission("read:users")}
          />
        </div>
      </Fragment>
    </Switch>
  );
};

const useStyles = makeStyles({
  container: {
    flex: 1,
    marginBottom: 100,
    marginTop: 64,
  },
});

export default LayoutRoutes;
