import ReactGA from "react-ga4";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "./auth0Provider";

const usePageTracking = () => {
  const { user } = useAuth0();
  ReactGA.initialize([
    {
      trackingId: process.env.REACT_APP_GA_TRACKING_ID!,
      gaOptions: {
        contentGroup1: user?.permissions ? user.permissions.join(",") : "none",
      },
    },
  ]);

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);
};

export default usePageTracking;
