import React, { ChangeEvent, FormEvent, useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import UpdateIntegrationEvents from "../../mutations/UpdateIntegrationEventsMutation";
import { integration_integration_integration_events } from "../../generated/Integration";
import { TransactionType as transactionType } from "../../generated/globalTypes";
import {
  UpdateIntegrationEventsVariables,
  UpdateIntegrationEvents as IUpdateIntegration,
} from "../../generated/UpdateIntegrationEvents";
import ConfirmationAlert from "../ConfirmationAlert";
import { Remove } from "@material-ui/icons";

interface IIntegrationsPostbackFormProps {
  type: "edit" | "create";
  loading?: boolean;
  integrationId?: string;
  events?: integration_integration_integration_events[];
  onCancel?: () => void;
}

interface IIntegrationsPostbackFormCreateProps
  extends IIntegrationsPostbackFormProps {
  events: any[];
}

interface IIntegrationsPostbackFormUpdateProps
  extends IIntegrationsPostbackFormProps {
  events: integration_integration_integration_events[];
}

const IntegrationsForm = ({
  type,
  integrationId,
  events: rawEvents,
  onCancel,
}:
  | IIntegrationsPostbackFormCreateProps
  | IIntegrationsPostbackFormUpdateProps) => {
  const classes = useStyles();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [events, setEvents] = useState([...rawEvents]);
  const [upsertPostback, { loading }] = useMutation<IUpdateIntegration>(
    UpdateIntegrationEvents
  );

  const onEventInputChange = (
    key: string,
    index: number,
    parser?: (v: unknown) => {}
  ) => {
    return (
      e: ChangeEvent<HTMLInputElement | { name?: string; value: unknown }>
    ) => {
      setDirty(true);
      setEvents((prev) => {
        const newEvents = [...(prev || [])];
        newEvents[index] = {
          ...newEvents[index],
          [key]: parser ? parser(e.target.value) : e.target.value,
        };
        return newEvents;
      });
    };
  };

  const getUpsertVariables = () => {
    let variables: UpdateIntegrationEventsVariables;
    variables = {
      integrationId: integrationId as string,
      events: events.map((event) => ({
        includeValue: event.includeValue,
        internalEvent: event.internalEvent,
        externalEvent: event.externalEvent,
      })),
    };
    console.log(variables);
    return variables;
  };

  const finishAndReset = () => {
    setDirty(false);
    setEvents([]);
    onCancel && onCancel();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    upsertPostback({
      refetchQueries: ["integration"],
      variables: getUpsertVariables(),
    }).then(() => {
      finishAndReset();
    });
  };

  const handleAddIntegrationEvent = () => {
    const newEvents = [...events];
    newEvents.push({
      internalEvent: Object.keys(transactionType)[0],
      externalEvent: "",
      includeValue: false,
    });
    setDirty(true);
    setEvents(newEvents);
  };

  const handleDeleteIntegrationEvent = (index: number) => {
    const newEvents = [...events.filter((_, i) => i !== index)];
    setDirty(true);
    setEvents(newEvents);
  };

  const handleDiscard = () => {
    setConfirmOpen(false);
    finishAndReset();
  };

  const onCancelAndReset = () => {
    dirty ? setConfirmOpen(true) : finishAndReset();
  };

  return (
    <form onSubmit={handleSubmit}>
      {events.map((event, index) => (
        <div className={classes.formElements} key={index}>
          {events.length > 0 ? (
            <>
              <Typography className={classes.formIndex}>{index + 1}</Typography>
              <FormControl>
                <InputLabel id={`internalEvent-${index}`}>
                  Internal Event
                </InputLabel>
                <Select
                  labelId={`internalEvent-${index}`}
                  id={`internalEvent-${index}`}
                  value={event.internalEvent}
                  onChange={onEventInputChange("internalEvent", index)}
                  disabled={loading}
                >
                  {Object.entries(transactionType).map(([key, value]) =>
                    value !== "CLICK" ? (
                      <MenuItem key={key} value={value}>
                        {value
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() +
                              word.slice(1).toLowerCase()
                          )
                          .join(" ")}
                      </MenuItem>
                    ) : null
                  )}
                </Select>
              </FormControl>
              <TextField
                id={`externalEvent-${index}`}
                label="Event"
                value={event.externalEvent}
                placeholder="CustomEvent"
                onChange={onEventInputChange("externalEvent", index)}
                disabled={loading}
              />

              <FormControl>
                <InputLabel id={`includeValue-${index}`}>
                  Attributes Sent
                </InputLabel>
                <Select
                  labelId={`includeValue-${index}`}
                  id={`includeValue-${index}`}
                  value={event.includeValue ? 2 : 1}
                  disabled={loading}
                  onChange={onEventInputChange(
                    "includeValue",
                    index,
                    (v) => parseInt(v as string, 10) === 2
                  )}
                >
                  <MenuItem value={1}>Event</MenuItem>
                  <MenuItem value={2}>Event and Value</MenuItem>
                </Select>
              </FormControl>
              <div className={classes.deleteButton}>
                <IconButton
                  title="Remove this integration postback event"
                  size="small"
                  onClick={() => handleDeleteIntegrationEvent(index)}
                >
                  <Remove />
                </IconButton>
              </div>
            </>
          ) : null}
        </div>
      ))}
      {events.length === 0 ? <Typography>No Postback Events</Typography> : null}
      <div className={classes.actionButtons}>
        <Button
          type="button"
          color="default"
          variant="outlined"
          disabled={loading}
          onClick={handleAddIntegrationEvent}
        >
          {events.length > 0 ? "Add" : "Add Postback Event"}
        </Button>
        <Button
          className={classes.save}
          type="submit"
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : type === "edit" ? (
            "Save"
          ) : (
            "Create"
          )}
        </Button>
        <Button
          className={classes.cancel}
          type="button"
          color="default"
          variant="outlined"
          onClick={onCancelAndReset}
          disabled={loading}
        >
          Cancel
        </Button>
        <ConfirmationAlert
          title="Unsaved Changes"
          content="Closing this form will lose any unsaved progress."
          open={confirmOpen}
          positiveAction="Discard"
          onNegative={() => setConfirmOpen(false)}
          onPositive={handleDiscard}
        />
      </div>
    </form>
  );
};

const useStyles = makeStyles(({ spacing, palette }) => ({
  formTitle: {
    marginBottom: spacing(3),

    "& h2": {
      fontSize: 18,
      fontWeight: 500,
    },
  },
  formElements: {
    display: "flex",
    flexDirection: "row",
    gap: spacing(2),

    "&:not(:last-child)": {
      marginBottom: spacing(2),
    },

    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  formIndex: {
    marginTop: "18px",
    marginRight: "15px",
  },
  actionButtons: {
    marginTop: spacing(4),
  },
  cancel: {
    float: "right",
    marginRight: "10px",
  },
  save: {
    float: "right",
  },
  card: {
    marginBottom: spacing(2),
  },
  cardTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "row",
    marginBottom: spacing(2),
    paddingBottom: spacing(1),
    borderBottom: `1px solid ${palette.grey[200]}`,

    "& .MuiTypography-root": {
      fontSize: 14,
      textTransform: "uppercase",
      fontWeight: 600,
    },
  },
  deleteButton: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingTop: spacing(1),
  },
  helpText: {
    fontSize: 12,
    marginTop: spacing(1),
    color: palette.grey[600],
  },
}));

export default IntegrationsForm;
